<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('banners.edit_translations') }}</h4>
        </md-card-header>

          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ banner.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('banners.back_banner') }}
                  </md-button>
                </div>
            </div>

        	<form @submit.prevent="update">
	            <md-card v-for="(translated_strings, langCode) in translations" :key="langCode">
	            	<md-card-header>
	            		<div class="md-title">{{ $t('languages.code_' + langCode) }}</div>
	        		</md-card-header>

                    <div class="md-layout spacing">
                      <label class="md-layout-item md-size-25 md-form-label">
                        {{ $t('image') }}
                      </label>
                      <div class="md-layout-item">
                        <div class="file-input">
                          <div v-if="translated_strings.image">
                            <div class="image-container">
                              <img :src="translated_strings.image" />
                            </div>
                          </div>
                          <div class="image-container" v-else>
                            <img :src="default_img" />
                          </div>
                          <div class="button-container">
                            <md-button
                              class="md-danger md-round"
                              :langcode="langCode"
                              @click="removeImage(langCode, $event)"
                              v-if="translated_strings.image"
                            >
                              <i class="fa fa-times" />
                              {{ $t('action.delete') }}
                            </md-button>
                            <md-button class="md-success md-fileinput">
                              <template v-if="!translated_strings.image">{{ $t('action.select_image') }}</template>
                              <template v-else>{{ $t('action.change') }}</template>
                              <input type="file" :langcode="langCode" @change="onImageChange" />
                            </md-button>
                          </div>
                          <span class="description" v-if="!translated_strings.image">Dimensioni consigliate: 1000x200</span>
                        </div>
                      </div>
                    </div>

		            <div class="md-layout spacing">
		              <label class="md-layout-item md-size-25 md-form-label">
		                {{ $t('title') }}
		              </label>
		              <div class="md-layout-item">
		                <md-field class="md-invalid">
		                  <md-input v-model="translated_strings.title" />
		                </md-field>
		              </div>
		            </div>

                    <div class="md-layout spacing">
                      <label class="md-layout-item md-size-25 md-form-label">
                        {{ $t('link') }}
                      </label>
                      <div class="md-layout-item">
                        <md-field class="md-invalid">
                          <md-input v-model="translated_strings.url" />
                        </md-field>
                      </div>
                    </div>
	            </md-card>

		          <md-card-actions md-alignment="right">
		            <md-button type="submit" class="md-success">{{ $t('action.save_changes') }}</md-button>
		          </md-card-actions>

            </form>
          </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  mixins: [formMixin],

  data: () => ({
    banner: {
      type: "banners",
      name: null,
      email: null,
      latitude: null,
      longitude: null
    },
    images: {},
    imageFiles: {},
    default_img: process.env.VUE_APP_BASE_URL + "/img/placeholder.jpg",
    translations: {},
    languages: []
  }),

  created() {
    this.getTranslations();
  },

  methods: {
    async getTranslations() {
      try {
        const id = this.$route.params.id;
        const banner_fields = [ 'title', 'url', 'image' ];

        await this.$store.dispatch("banners/get_translations", id);
        this.banner = this.$store.getters["banners/banner"];
        this.languages = this.$store.languages;
        this.translations = this.$store.$app.initTranslations(this.$store.getters["banners/translations"], banner_fields);
/*
        for (var langCode in this.translations) {
            Vue.set(this.translations[langCode], 'image', this.translations[langCode].image || null);
        }
*/
      }
      catch (e) {
        console.log(e);
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    async update() {
        for (var langCode in this.imageFiles) {
          if (this.imageFiles[langCode]) {
              let self = this;
              await this.$store.dispatch("banners/uploadTranslatedImage", {
                banner: this.banner,
                image: this.imageFiles[langCode]
              }).then(function(response) {
                self.translations[langCode].image = response;
              });
          }
        }

        await this.$store.dispatch("banners/updateTranslations", { banner:this.banner, translations:this.translations });
        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );
        this.goBack();
	},

    onImageChange(e) {
      const langCode = e.target.getAttribute('langCode');

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], langCode);
    },

    createImage(file, langCode) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.translations[langCode].image = e.target.result;
        this.imageFiles[langCode] = file;
      };
      reader.readAsDataURL(file);
    },

    removeImage(langCode, e) {
      this.translations[langCode].image = null;
      this.imageFiles[langCode] = null;
    },

    goBack() {
      this.$router.push({ name: "banners.edit_banner", id: this.banner.id }).catch(err => {});
    }
  }
};
</script>
