import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/map_points`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/map_points/${id}`, options)
    .then(response => {
      let map_point = jsona.deserialize(response.data);
      delete map_point.links;
      return map_point;
    });
}

function add(map_point) {
  const payload = jsona.serialize({
    stuff: map_point,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/map_points`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(map_point) {
  const payload = jsona.serialize({
    stuff: company,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/map_points/${map_point.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/map_points/${id}`, options);
}

function upload(company, image) {
  const payload = new FormData();
  payload.append('attachment', image);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/uploads/map_points/${map_point.id}/image`, payload, options)
    .then(response => {
      return response.data.image_path;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload
};

