import service from '@/store/services/companies-service';

const state = {
  list: {},
  company: {},
  meta: {},
  url: null,
  image_path: null,
  tracks: [],
  translations: {},
  company_types: []
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, company) => {
    state.company = company;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_IMAGE_PATH: (state, image_path) => {
    state.image_path = image_path;
  },
  SET_FEATURES: (state, features) => {
	state.features = features;
	state.company.company_features = features;
  },
  SET_PHOTOS: (state, photos) => {
    state.photos = photos;
  },
  SET_TRACKS: (state, tracks) => {
    state.tracks = tracks;
  },
  SET_TRANSLATIONS: (state, translations) => {
    state.translations = translations;
  },
  SET_COMPANY_TYPES: (state, company_types) => {
    state.company_types = company_types;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((company) => { commit('SET_RESOURCE', company); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((company) => { commit('SET_RESOURCE', company); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((company) => { commit('SET_RESOURCE', company); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, {company, image}) {
    return service.upload(company, image)
      .then((image_path) => {
        commit('SET_IMAGE_PATH', image_path);
      });
  },

  get_with_features({commit, dispatch}, params) {
    return service.get_with_features(params)
      .then((company) => { commit('SET_RESOURCE', company); commit('SET_FEATURES', company.company_features); });
  },

  update_features({commit, dispatch}, params) {
    return service.update_features(params)
    .then((company) => { commit('SET_RESOURCE', company); });
  },

  get_with_photos({commit, dispatch}, params) {
    return service.get_with_photos(params)
      .then((company) => { commit('SET_RESOURCE', company); commit('SET_PHOTOS', company.company_photos); });
  },

  edit_photo({commit, dispatch}, params) {
    return service.edit_photo(params);
  },

  destroy_photo({commit, dispatch}, params) {
    return service.destroy_photo(params);
  },  

  getTracksForEdit({commit, dispatch}, companyId) {
    return service.getTracksForEdit(companyId)
      .then((tracks) => { commit('SET_TRACKS', tracks); });
  },

  updateTracks({commit, dispatch}, { company, company_tracks }) {
    return service.updateTracks(company, company_tracks);
  },

  updateTypes({commit, dispatch}, { company, company_types }) {
    return service.updateTypes(company, company_types);
  },

  get_translations({commit, dispatch}, companyId) {
    return service.get_translations(companyId)
    .then((company) => { commit('SET_RESOURCE', company); commit('SET_TRANSLATIONS', company.meta.translations); });
  },

  updateTranslations({commit, dispatch}, { company, translations }) {
    return service.updateTranslations(company, translations);
  },

  get_company_types({commit, dispatch}, params) {
    return service.get_company_types(params)
      .then((company_types) => { commit('SET_COMPANY_TYPES', company_types); });
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  company: state => state.company,
  url: state => state.url,
  image_path: state => state.image_path,
  photos: state => state.photos,
  features: state => state.features,
  tracks: state => state.tracks,
  translations: state => state.translations,
  company_types: state => state.company_types
};

const companies = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default companies;
