// =========================================================
// * Vue Material Dashboard Laravel PRO - v1.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-laravel-pro
// * Copyright Creative Tim (https://www.creative-tim.com) & UPDIVISION (https://www.updivision.com)
//
// * Coded by www.creative-tim.com & www.updivision.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import axios from "axios";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import VueMeta from 'vue-meta'
import IsDemo from './isDemo'

// Font Awesome
import '@fortawesome/fontawesome-free/css/all.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faJs, faVuejs } from '@fortawesome/free-brands-svg-icons';
library.add(fab, faJs, faVuejs);

// Google Maps
import { API_KEY } from "./pages/Dashboard/Maps/API_KEY";
import { Loader, LoaderOptions } from "google-maps";
const gmapLoader = new Loader(API_KEY);
Vue.prototype.$gmapLoader = gmapLoader;

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(IsDemo);
Vue.use(VueMeta, { keyName: 'head' });

// multilanguage
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n = new VueI18n({ locale: process.env.VUE_APP_I18N_LOCALE || 'it', messages: loadLocaleMessages() });

//moment
import VueMoment from "vue-moment";
var moment = require('moment-timezone');
moment.locale(i18n.locale);
moment.tz.setDefault('Europe/Rome');
moment.suppressDeprecationWarnings = true;
Vue.use(VueMoment, { moment });

// router & store setup
import router from "./router";
import store from "./store";

// global library setup
Vue.prototype.$Chartist = Chartist;

Vue.filter('capitalize', function (value) {
	if (!value) return '';

	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('moment_tz', function (value, format) {
	if (!value) return '';
	return moment(value).tz('Europe/Rome').format(format);
});

Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    // Avoid error on item number selection in pager
    if (err.message !== "Cannot read property 'badInput' of undefined") {
      console.error(err);
    }
  }
};

/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  i18n,
  render: h => h(App)
});

app.$material.locale.firstDayOfAWeek = 1;
app.$material.locale.dateFormat = 'dd/MM/yyyy';

store.languages = [ 'en', 'de', 'es' ];

app.initTranslations = function(translations, fields) {
	var res = {};

	for (var k in store.languages) {
		var langCode = store.languages[k];
		if (res[langCode] == undefined) res[langCode] = {};
		for (var k in fields) {
			res[langCode][fields[k]] = translations[langCode] == undefined ? '' : translations[langCode][fields[k]];
		}
	}

	return res;
};

store.$app = app;

function loadLocaleMessages() {
	const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}

	locales.keys().forEach(key => {
	    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
	    if (matched && matched.length > 1) {
	      const locale = matched[1]
	      messages[locale] = locales(key)
	    }
	});

	return messages;
}