<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>contacts</md-icon>
          </div>
          <h4 class="title">{{ $t('banners.edit_banner') }}</h4>
        </md-card-header>

        <form @submit.prevent="update">
          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left">
                  <div class="md-list" id="track-edit-actions-dropdown">
                    <md-list-item href="javascript:;" class="dropdown">
                        <drop-down>
                            <md-button class="dropdown-toggle md-info md-dense" data-toggle="dropdown">Modifica</md-button>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="#" @click="goEditTranslations"><md-icon>translate</md-icon><span>{{ $t('action.translations') }}</span></a>
                                </li>
                            </ul>
                        </drop-down>
                    </md-list-item>
                  </div>
                  <md-button @click="goEditTranslations" class="md-medium-hide md-info md-dense">
                    {{ $t('action.translations') }}
                  </md-button>
                </div>

                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('action.back_list') }}
                  </md-button>
                </div>
            </div>

            <div class="md-layout">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('image') }}
              </label>
              <div class="md-layout-item">
                <div class="file-input">
                  <div v-if="image">
                    <div class="image-container">
                      <img :src="image" />
                    </div>
                  </div>
                  <div class="image-container" v-else>
                    <img :src="default_img" />
                  </div>
                  <div class="button-container">
                    <md-button
                      class="md-danger md-round"
                      @click="removeImage"
                      v-if="image"
                    >
                      <i class="fa fa-times" />
                      {{ $t('action.delete') }}
                    </md-button>
                    <md-button class="md-success md-fileinput">
                      <template v-if="!image">{{ $t('action.select_image') }}</template>
                      <template v-else>{{ $t('action.change') }}</template>
                      <input type="file" @change="onFileChange" />
                    </md-button>
                  </div>
                  <span class="description" v-if="!image">Dimensioni consigliate: 1000x200</span>
                </div>
                <md-field class="md-invalid">
                  <validation-error :errors="apiValidationErrors.attachment" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('title') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="banner.title" required />
                  <validation-error :errors="apiValidationErrors.title" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('status') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>{{ $t('select.placeholder') }}</label>
                  <md-select required
                    v-if="available_statuses"
                    v-model="banner.status"
                    name="status"
                  >
                    <md-option
                      v-for="item in available_statuses"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.label }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('date_from') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                    <md-datepicker v-model="banner.date_from" md-immediately required>
                        <label>{{ $t('select_date.placeholder') }}</label>
                    </md-datepicker>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('date_to') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                    <md-datepicker v-model="banner.date_to" md-immediately required>
                        <label>{{ $t('select_date.placeholder') }}</label>
                    </md-datepicker>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('link') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="banner.url" />
                  <validation-error :errors="apiValidationErrors.url" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('phone_number') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="banner.phone_number" />
                  <validation-error :errors="apiValidationErrors.phone_number" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('banners.link_type') }}
              </label>
              <div class="md-layout-item">
                <md-radio v-model="banner.link_type" value="url">{{ $t('banners.link_type_url') }}</md-radio>
                <md-radio v-model="banner.link_type" value="phone_number">{{ $t('banners.link_type_phone_number') }}</md-radio>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('tracks') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>{{ $t('select.placeholder') }}</label>
                  <md-select multiple
                    v-model="banner_tracks"
                    name="banner_tracks"
                  >
                    <md-option
                      v-for="track in tracks"
                      :key="track.id"
                      :value="track.id"
                    >
                      {{ track.name }} ({{ parseInt(track.length / 1000) }} km)
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('background_color_1') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="banner.background_color_1" />
                  <validation-error :errors="apiValidationErrors.background_color_1" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('background_color_2') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="banner.background_color_2" />
                  <validation-error :errors="apiValidationErrors.background_color_2" />
                </md-field>
              </div>
            </div>

          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button type="submit" class="md-success">{{ $t('action.save_changes') }}</md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import moment from "moment";

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    banner: {
      type: "banners",
      title: null,
      status: null,
      image: null,
      date_from: null,
      date_to: null,
      url: null,
      phone_number: null,
      link_type: "url"
    },

    image: "",
    default_img: process.env.VUE_APP_BASE_URL + "/img/placeholder.jpg",
    file: null,
    available_statuses: [],
    tracks: [],
    banner_tracks: []
  }),

  created() {
    this.get();
    this.getTracks();
    this.$material.locale.dateFormat = 'dd/MM/yyyy';
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("banners/get", id);
        let banner = this.$store.getters["banners/banner"];
        let myDate;

        if (banner.date_from) {
            myDate = new Date(banner.date_from);
            banner.date_from = moment(myDate).format('DD/MM/YYYY');
        }
        if (banner.date_to) {
            myDate = new Date(banner.date_to);
            banner.date_to = moment(myDate).format('DD/MM/YYYY');
        }

        this.banner = banner;
        this.image = this.banner.image;
        this.available_statuses = [{ 'id': 'active', 'label': this.$t('banners.status_active') }, { 'id': 'inactive', 'label': this.$t('banners.status_inactive') }];
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    async getTracks() {
        await this.$store.dispatch("banners/getTracksForEdit", this.$route.params.id);
        this.tracks = this.$store.getters["banners/tracks"];

        this.banner_tracks = [];
        for (var k in this.tracks) {
            if (this.tracks[k].selected) this.banner_tracks.push(this.tracks[k].id);
        }
    },

    async update() {
      try {
        this.resetApiValidation();
        if (this.file) {
          await this.$store.dispatch("banners/upload", {
            banner: this.banner,
            image: this.file
          });
          this.banner.image = await this.$store.getters["banners/image_path"];
        }

        this.$material.locale.dateFormat = 'yyyy-MM-dd';

        let banner = _.clone(this.banner);
        if (banner.date_from) banner.date_from = banner.date_from.split('/').reverse().join('-');
        if (banner.date_to) banner.date_to = banner.date_to.split('/').reverse().join('-');
        await this.$store.dispatch("banners/update", banner);

        await this.$store.dispatch("banners/updateTracks", { banner: this.banner, banner_tracks: this.banner_tracks });

        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );
        this.goBack();
      }
      catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            this.$t(e.response.data.errors[0].title) + (e.response.data.errors[0].detail ? ' ' + this.$t(e.response.data.errors[0].detail) : '')
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            "alerts/error",
            this.$t('message.error_generic')
          );
        }
      }
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },

    removeImage() {
      this.image = null;
      this.banner.image = null;
    },

    goEditTranslations() {
      this.$router.push({ name: "banners.edit_translations" }).catch(err => {});
    },

    goBack() {
      this.$router.push({ name: "banners.list_banners" }).catch(err => {});
    }
  }
};
</script>

<style lang="scss">
.spacing { padding-bottom: 1rem; }
</style>
