import service from '@/store/services/provinces-service';

const state = {
  list: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
      });
  }
};

const getters = {
  list: state => state.list,
  dropdown: (state) => {
    return state.list.map(province => ({
      id: province.id,
      name: province.name
    }));
  }
};

const provinces = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default provinces;
