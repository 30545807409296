<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('places.edit_place_features') }}</h4>
        </md-card-header>

        <form @submit.prevent="update_features">
          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ place.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('places.back_place') }}
                  </md-button>
                </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('places.reservation_required') }}
              </label>
              <div class="md-layout-item">
                <md-radio v-model="features.reservation_required" value="yes">{{ $t('yes') }}</md-radio>
                <md-radio v-model="features.reservation_required" value="no">{{ $t('no') }}</md-radio>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('places.guided_tour') }}
              </label>
              <div class="md-layout-item">
                <md-radio v-model="features.guided_tour" value="yes">{{ $t('yes') }}</md-radio>
                <md-radio v-model="features.guided_tour" value="no">{{ $t('no') }}</md-radio>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('places.disabled_access') }}
              </label>
              <div class="md-layout-item">
                <md-radio v-model="features.disabled_access" value="yes">{{ $t('yes') }}</md-radio>
                <md-radio v-model="features.disabled_access" value="no">{{ $t('no') }}</md-radio>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('places.reachable') }}
              </label>
              <div class="md-layout-item">
                  <md-checkbox v-model="features.reachable" value="walking_distance">{{ $t('place_feature.reachable_walking_distance') }}</md-checkbox>
                  <md-checkbox v-model="features.reachable" value="bicycle">{{ $t('place_feature.reachable_bicycle') }}</md-checkbox>
                  <md-checkbox v-model="features.reachable" value="car">{{ $t('place_feature.reachable_car') }}</md-checkbox>
                  <md-checkbox v-model="features.reachable" value="public_transportation">{{ $t('place_feature.reachable_public_transportation') }}</md-checkbox>
              </div>
            </div>

            <div class="md-layout spacing" v-show="false">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('places.place_type') }}
              </label>
              <div class="md-layout-item">
                  <md-checkbox v-model="features.place_type" value="culture">{{ $t('place_feature.place_type_culture') }}</md-checkbox>
                  <md-checkbox v-model="features.place_type" value="nature">{{ $t('place_feature.place_type_nature') }}</md-checkbox>
                  <md-checkbox v-model="features.place_type" value="history">{{ $t('place_feature.place_type_history') }}</md-checkbox>
              </div>
            </div>

          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button type="submit" class="md-success">{{ $t('action.save_changes') }}</md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  components: { },

  mixins: [formMixin],

  data: () => ({
    place: {
      type: "places",
      name: null,
      email: null,
      latitude: null,
      longitude: null
    },
    features: {}
  }),

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("places/get_with_features", id);
        this.place = this.$store.getters["places/place"];
        this.features = this.featuresList();
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    async update_features() {
      try {
        await this.$store.dispatch("places/update_features", { place: this.place, features: this.features });
        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            this.$t(e.response.data.errors[0].title) + (e.response.data.errors[0].detail ? ' ' + this.$t(e.response.data.errors[0].detail) : '')
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            "alerts/error",
            this.$t('message.error_generic')
          );
        }
      }
    },

    featuresList() {
      var myFeatures = this.getDefaultFeatures();

      for (var k in this.place.place_features) {
          const featureKey = this.place.place_features[k]['feature_key'];
          const featureValue = this.place.place_features[k]['feature_value'];

          if (Array.isArray(myFeatures[featureKey])) {
              myFeatures[featureKey].push(featureValue);
          }
          else {
              myFeatures[featureKey] = featureValue;
          }
      }

      return myFeatures;
    },

    goBack() {
      this.$router.push({ name: "places.edit_place", id: this.place.id }).catch(err => {});
    },

    getDefaultFeatures() {
      return {
          reservation_required: null,
          guided_tour: null,
          disabled_access: null,
          reachable: [],
          track_theme: []
          //place_type: []
      }
    }
  }
};
</script>

<style lang="scss">
.spacing { padding-bottom: 1rem; }
</style>
