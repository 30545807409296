import service from '@/store/services/stats-service';

const state = {
  h24: {},
  recent: {}
};

const mutations = {
  SET_H24: (state, stats) => {
    state.h24 = stats;
  },
  SET_RECENT: (state, stats) => {
    state.recent = stats;
  }
};

const actions = {
  getH24({commit, dispatch}, params) {
    return service.getH24()
      .then((stats) => { commit('SET_H24', stats); });
  },

  getRecent({commit, dispatch}, params) {
	return service.getRecent(params.months || 3)
	  .then((stats) => { commit('SET_RECENT', stats); });
  }
};

const getters = {
  getH24: state => state.h24,
  getRecent: state => state.recent
};

const stats = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default stats;
