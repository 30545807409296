import service from '@/store/services/tracks-service';

const state = {
  list: {},
  track: {},
  meta: {},
  url: null,
  image_path: null,
  features: {},
  photos: [],
  points: [],
  translations: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, track) => {
    state.track = track;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_IMAGE_PATH: (state, image_path) => {
    state.image_path = image_path;
  },
  SET_FEATURES: (state, features) => {
	state.features = features;
	state.track.track_features = features;
  },
  SET_PHOTOS: (state, photos) => {
    state.photos = photos;
  },
  SET_POINTS: (state, points) => {
    state.points = points;
  },
  SET_TRANSLATIONS: (state, translations) => {
    state.translations = translations;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((track) => { commit('SET_RESOURCE', track); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((track) => { commit('SET_RESOURCE', track); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((track) => { commit('SET_RESOURCE', track); });
  },

  update_features({commit, dispatch}, params) {
    return service.update_features(params)
    .then((track) => { commit('SET_RESOURCE', track); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, {track, image}) {
    return service.upload(track, image)
      .then((image_path) => {
        commit('SET_IMAGE_PATH', image_path);
      });
  },

  get_with_features({commit, dispatch}, params) {
    return service.get_with_features(params)
      .then((track) => { commit('SET_RESOURCE', track); commit('SET_FEATURES', track.track_features); });
  },

  get_with_photos({commit, dispatch}, params) {
    return service.get_with_photos(params)
      .then((track) => { commit('SET_RESOURCE', track); commit('SET_PHOTOS', track.track_photos); });
  },

  edit_photo({commit, dispatch}, params) {
    return service.edit_photo(params);
  },

  destroy_photo({commit, dispatch}, params) {
    return service.destroy_photo(params);
  },

  get_with_points({commit, dispatch}, params) {
    return service.get_with_points(params)
      .then((track) => { commit('SET_RESOURCE', track); commit('SET_POINTS', track.track_points); });
  },

  get_translations({commit, dispatch}, trackId) {
    return service.get_translations(trackId)
    .then((track) => { commit('SET_RESOURCE', track); commit('SET_TRANSLATIONS', track.meta.translations); });
  },

  updateTranslations({commit, dispatch}, { track, translations }) {
    return service.updateTranslations(track, translations);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  track: state => state.track,
  url: state => state.url,
  image_path: state => state.image_path,
  features: state => state.features,
  photos: state => state.photos,
  points: state => state.points,
  translations: state => state.translations
};

const tracks = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default tracks;