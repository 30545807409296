<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>contacts</md-icon>
          </div>
          <h4 class="title">{{ $t('offers.add_offer') }}</h4>
        </md-card-header>

        <form @submit.prevent="add">
          <md-card-content>
            <div class="text-right">
              <md-button @click="goBack" class="md-primary md-dense">
                {{ $t('action.back_list') }}
              </md-button>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('image') }}
              </label>
              <div class="md-layout-item">
                <div class="file-input">
                  <div v-if="image">
                    <div class="image-container">
                      <img :src="image" />
                    </div>
                  </div>
                  <div class="image-container" v-else>
                    <img :src="default_img" />
                  </div>
                  <div class="button-container">
                    <md-button
                      class="md-danger md-round"
                      @click="removeImage"
                      v-if="image"
                    >
                      <i class="fa fa-times" />
                      {{ $t('action.delete') }}
                    </md-button>
                    <md-button class="md-success md-fileinput">
                      <template v-if="!image">{{ $t('action.select_image') }}</template>
                      <template v-else>{{ $t('action.change') }}</template>
                      <input type="file" @change="onFileChange" />
                    </md-button>
                  </div>
                </div>
                <md-field class="md-invalid">
                  <validation-error :errors="apiValidationErrors.attachment" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('title') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="offer.title" />
                  <validation-error :errors="apiValidationErrors.title" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('status') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>{{ $t('select.placeholder') }}</label>
                  <md-select
                    v-if="available_statuses"
                    v-model="offer.status"
                    name="status"
                  >
                    <md-option
                      v-for="item in available_statuses"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.label }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('company') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>{{ $t('select.placeholder') }}</label>
                  <md-select
                    v-model="offer.company_id"
                    name="company"
                  >
                    <md-option
                      v-for="company in companies"
                      :key="company.id"
                      :value="company.id"
                    >
                      {{ company.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('date_from') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                    <md-datepicker v-model="offer.date_from" md-immediately required>
                          <label>{{ $t('select_date.placeholder') }}</label>
                    </md-datepicker>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('date_to') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                    <md-datepicker v-model="offer.date_to" md-immediately required>
                          <label>{{ $t('select_date.placeholder') }}</label>
                    </md-datepicker>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('description') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-textarea v-model="offer.description" required></md-textarea>
                  <validation-error :errors="apiValidationErrors.description" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('link') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="offer.url" required />
                  <validation-error :errors="apiValidationErrors.url" />
                </md-field>
              </div>
            </div>

          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button type="submit" class="md-success">{{ $t('offers.add_offer') }}</md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import moment from "moment";

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    offer: {
      type: "offers",
      title: null,
      status: null,
      image: null,
      company_id: null,
      date_from: null,
      date_to: null,
      url: null
    },

    image: "",
    default_img: process.env.VUE_APP_BASE_URL + "/img/placeholder.jpg",
    file: null,
    companies: []
  }),

  created() {
    this.getCompanies();
    this.getStatuses();
  },

  methods: {
    async getCompanies() {
        await this.$store.dispatch("companies/list");
        this.companies = this.$store.getters["companies/list"];
    },

    async add() {
      try {
        this.resetApiValidation();
        delete this.offer.id;

        // update offer with image url
        let offer = _.clone(this.offer), myDate;

        if (offer.date_from) {
            myDate = new Date(offer.date_from);
            offer.date_from = moment(myDate).format('YYYY-MM-DD');
        }
        if (offer.date_to) {
            myDate = new Date(offer.date_to);
            offer.date_to = moment(myDate).format('YYYY-MM-DD');
        }
        await this.$store.dispatch("offers/add", offer);
        offer = await this.$store.getters["offers/offer"];

        if (this.file) {
          // upload image and get image url
          await this.$store.dispatch("offers/upload", {
            offer: offer,
            image: this.file,
          });
          offer.image = await this.$store.getters["offers/image_path"];
        }
        await this.$store.dispatch("offers/update", offer);

        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );

        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            "alerts/error",
            this.$t('message.error_generic')
          );
          this.setApiValidation(e.response.data.errors);
        }
        if (this.offer.id) {
          await this.$store.dispatch("offers/destroy", this.offer.id);
        }
      }
    },

    getStatuses() {
      this.available_statuses = [{ 'id': 'active', 'label': this.$t('offers.status_active') }, { 'id': 'inactive', 'label': this.$t('offers.status_inactive') }];
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },

    removeImage() {
      this.image = null;
    },

    goBack() {
      this.$router.push({ name: "offers.list_offers" }).catch(err => {});
    }
  }
};
</script>

<style lang="scss">
.spacing { padding-bottom: 1rem; }
</style>
