import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
//import categories from "./modules/categories-module";
//import tags from "./modules/tags-module";
//import items from "./modules/items-module";

// TurismoVittorioVeneto modules
import companies from "./modules/companies-module";
import offers from "./modules/offers-module";
import banners from "./modules/banners-module";
import tracks from "./modules/tracks-module";
import map_points from "./modules/map_points-module";
import provinces from "./modules/provinces-module";
import stats from "./modules/stats-module";
import places from "./modules/places-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    roles,
//    categories,
//    tags,
//    items,
    companies,
    offers,
    banners,
    tracks,
    provinces,
    map_points,
    places,
    stats
  }
});
