import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta';
import store from "../store";
import NProgress from 'nprogress';
import axios from 'axios';

Vue.use(VueRouter);
Vue.use(VueMeta)

// router setup
import routes from "./routes";

// configure router
const router = new VueRouter({
  mode: "hash",
  routes, // short for routes: routes
  scrollBehavior: to => {
    if (to.hash) {
      return {selector: to.hash};
    } else {
      return {x: 0, y: 0};
    }
  },
  linkExactActiveClass: "nav-item active"
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware)
    return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({...context, next: nextMiddleware});
  };
}

router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    // redirect to Login if user is not authenticated
	const isAuthenticated = store.state.auth.isAuthenticated;
    if (to.name !== 'Login' && !isAuthenticated) {
    	next({ name: 'Login' })
    }
    else {
	    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
	    const context = {from, next, to, router};
	    const nextMiddleware = nextFactory(context, middleware, 1);

	    return middleware[0]({...context, next: nextMiddleware});
    }
  }

  return next();
});

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    NProgress.start();
    return config;
  }, function (error) {
    // Do something with request error
    NProgress.done();
    console.log(error);
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    NProgress.done();
    return response;
  }, function (error) {
    // Do something with response error
    NProgress.done();
    console.log(error);
    return Promise.reject(error);
});

export default router;
