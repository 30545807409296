import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/offers`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/offers/${id}`, options)
    .then(response => {
      let offer = jsona.deserialize(response.data);
      delete offer.links;
      return offer;
    });
}

function add(offer) {
  const payload = jsona.serialize({
    stuff: offer,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/offers`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(offer) {
  const payload = jsona.serialize({
    stuff: offer,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/offers/${offer.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/offers/${id}`, options);
}

function upload(offer, image) {
  const payload = new FormData();
  payload.append('attachment', image);
  payload.append('has_thumb', '400|400');

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/uploads/offers/${offer.id}/image`, payload, options)
    .then(response => {
      return response.data.image_path;
    });
}

function getTracksForEdit(offerId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/tracks_offers/offer/${offerId}/edit`, options)
    .then(response => {
    	return response.data.tracks;
    });
}

function updateTracks(offer, offer_tracks) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/tracks_offers/update_tracks/${offer.id}`, { tracks: offer_tracks }, options);
}

function get_translations(offerId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/offers/${offerId}?with_translations=1`)
    .then(response => {
        let offer = jsona.deserialize(response.data);
        delete offer.links;
        return offer;
    });
}

function updateTranslations(offer, translations) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/update_translations`, { translations: translations, entity_type:'offer', entity_id:offer.id }, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  getTracksForEdit,
  updateTracks,
  get_translations,
  updateTranslations
};
