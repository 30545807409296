import service from '@/store/services/banners-service';

const state = {
  list: {},
  banner: {},
  meta: {},
  url: null,
  phone_number: null,
  link_type: null,
  image_path: null,
  translations: {},
  tracks: []
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, banner) => {
    state.banner = banner;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_IMAGE: (state, image_path) => {
    state.image_path = image_path;
  },
  SET_TRANSLATIONS: (state, translations) => {
    state.translations = translations;
  },
  SET_TRACKS: (state, tracks) => {
    state.tracks = tracks;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((banner) => { commit('SET_RESOURCE', banner); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((banner) => { commit('SET_RESOURCE', banner); commit('SET_TRACKS', []); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((banner) => { commit('SET_RESOURCE', banner); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, {banner, image}) {
    return service.upload(banner, image)
      .then((image_path) => {
        commit('SET_IMAGE', image_path);
      });
  },

  get_translations({commit, dispatch}, bannerId) {
    return service.get_translations(bannerId)
    .then((banner) => { commit('SET_RESOURCE', banner); commit('SET_TRANSLATIONS', banner.meta.translations); });
  },

  updateTranslations({commit, dispatch}, { banner, translations }) {
    return service.updateTranslations(banner, translations);
  },

  uploadTranslatedImage({commit, dispatch}, {banner, image, langCode}) {
    return service.uploadTranslatedImage(banner, image, langCode)
      .then((image_path) => {
        return image_path;
    });
  },

  getTracksForEdit({commit, dispatch}, bannerId) {
    return service.getTracksForEdit(bannerId)
      .then((tracks) => { commit('SET_TRACKS', tracks); });
  },

  updateTracks({commit, dispatch}, { banner, banner_tracks }) {
    return service.updateTracks(banner, banner_tracks);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  banner: state => state.banner,
  url: state => state.url,
  image_path: state => state.image_path,
  tracks: state => state.tracks,
  translations: state => state.translations
};

const banners = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default banners;
