import service from '@/store/services/map_points-service';

const state = {
  list: {},
  map_point: {},
  meta: {},
  url: null,
  image_path: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, map_point) => {
    state.map_point = map_point;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_IMAGE_PATH: (state, image_path) => {
    state.image_path = image_path;
  }  
};

const actions = {
  list_business({commit, dispatch}, params) {
	params.filter.point_type = 'business';

    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  list_service({commit, dispatch}, params) {
	params.filter.point_type = 'service';

    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((map_point) => { commit('SET_RESOURCE', map_point); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((map_point) => { commit('SET_RESOURCE', map_point); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((map_point) => { commit('SET_RESOURCE', map_point); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, {map_point, image}) {
    return service.upload(map_point, image)
      .then((image_path) => {
        commit('SET_IMAGE_PATH', image_path);
      });
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  map_point: state => state.map_point,
  url: state => state.url,
  image_path: state => state.image_path
};

const map_points = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default map_points;
