import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/companies`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/companies/${id}`, options)
    .then(response => {
      let company = jsona.deserialize(response.data);
      delete company.links;
      return company;
    });
}

function add(company) {
  const payload = jsona.serialize({
    stuff: company,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/companies`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(company) {
  const payload = jsona.serialize({
    stuff: company,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/companies/${company.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/companies/${id}`, options);
}

function upload(company, image) {
  const payload = new FormData();
  payload.append('attachment', image);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/uploads/companies/${company.id}/image`, payload, options)
    .then(response => {
      return response.data.image_path;
    });
}

function get_with_features(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/companies/${id}?include=company_features`, options)
    .then(response => {
      let company = jsona.deserialize(response.data);
      delete company.links;
      return company;
    });
}

function get_with_photos(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/companies/${id}?include=company_photos`, options)
    .then(response => {
      let company = jsona.deserialize(response.data);
      delete company.links;
      return company;
    });
}

function update_features(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/save/company/${params.company.id}/features`, { features: params.features }, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update_photos(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/save/company/${params.company.id}/photos`, params.photos, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function edit_photo(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.put(`${url}/edit/company_photo/${params.company_id}/${params.photo.id}`, params.photo, options);
}

function destroy_photo(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/delete/company_photo/${params.company_id}/${params.photo_id}`, options);
}

function getTracksForEdit(companyId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  let tracks_url = companyId ? `${url}/tracks_companies/company/${companyId}/edit` : `${url}/tracks_companies`;
  return axios.get(tracks_url, options)
    .then(response => {
    	return response.data.tracks;
    });
}

function updateTracks(company, company_tracks) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/tracks_companies/update_tracks/${company.id}`, { tracks: company_tracks }, options);
}

function updateTypes(company, company_types) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/company_types/save/${company.id}`, { types: company_types }, options);
}

function get_translations(companyId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/companies/${companyId}?with_translations=1`)
    .then(response => {
        let company = jsona.deserialize(response.data);
        delete company.links;
        return company;
    });
}

function updateTranslations(company, translations) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/update_translations`, { translations: translations, entity_type:'company', entity_id:company.id }, options);
}

function get_company_types() {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/company_types`, options)
    .then(response => {
      return response.data;
  });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  get_with_features,
  update_features,
  get_with_photos,
  update_photos,
  edit_photo,
  destroy_photo,
  getTracksForEdit,
  updateTracks,
  updateTypes,
  get_translations,
  updateTranslations,
  get_company_types
};