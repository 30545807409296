<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('tracks.edit_track_features') }}</h4>
        </md-card-header>

        <form @submit.prevent="update_features">
          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ company.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('companies.back_company') }}
                  </md-button>
                </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('companies.delivery') }}
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="features.delivery" value="home">{{ $t('company_feature.delivery_home') }}</md-checkbox>
                <md-checkbox v-model="features.delivery" value="take_away">{{ $t('company_feature.delivery_take_away') }}</md-checkbox>
              </div>
            </div>

          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button type="submit" class="md-success">{{ $t('action.save_changes') }}</md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  components: { },

  mixins: [formMixin],

  data: () => ({
    company: {
      type: "companies",
      name: null,
      email: null,
      latitude: null,
      longitude: null
    },
    features: {}
  }),

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("companies/get_with_features", id);
        this.company = this.$store.getters["companies/company"];
        console.log(this.company);
        this.features = this.featuresList();
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    async update_features() {
      try {
        await this.$store.dispatch("companies/update_features", { company: this.company, features: this.features });
        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            this.$t(e.response.data.errors[0].title) + (e.response.data.errors[0].detail ? ' ' + this.$t(e.response.data.errors[0].detail) : '')
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            "alerts/error",
            this.$t('message.error_generic')
          );
        }
      }
    },

    featuresList() {
      var myFeatures = this.getDefaultFeatures();
console.log(this.company.company_features);
      for (var k in this.company.company_features) {
          const featureKey = this.company.company_features[k]['feature_key'];
          const featureValue = this.company.company_features[k]['feature_value'];

          if (Array.isArray(myFeatures[featureKey])) {
              myFeatures[featureKey].push(featureValue);
          }
          else {
              myFeatures[featureKey] = featureValue;
          }
      }

      return myFeatures;
    },

    goBack() {
      this.$router.push({ name: "companies.edit_company", id: this.company.id }).catch(err => {});
    },

    getDefaultFeatures() {
      return {
          delivery: []
      }
    }
  }
};
</script>

<style lang="scss">
.spacing { padding-bottom: 1rem; }
</style>