import service from '@/store/services/places-service';

const state = {
  list: {},
  place: {},
  meta: {},
  url: null,
  image_path: null,
  tracks: [],
  features: {},
  photos: [],
  translations: {},
  place_types: []
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, place) => {
    state.place = place;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_IMAGE_PATH: (state, image_path) => {
    state.image_path = image_path;
  },
  SET_FEATURES: (state, features) => {
	state.features = features;
	state.place.place_features = features;
  },
  SET_PHOTOS: (state, photos) => {
    state.photos = photos;
  },
  SET_TRACKS: (state, tracks) => {
    state.tracks = tracks;
  },
  SET_TRANSLATIONS: (state, translations) => {
    state.translations = translations;
  },
  SET_PLACE_TYPES: (state, place_types) => {
    state.place_types = place_types;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((place) => { commit('SET_RESOURCE', place); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((place) => { commit('SET_RESOURCE', place); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((place) => { commit('SET_RESOURCE', place); });
  },

  update_features({commit, dispatch}, params) {
    return service.update_features(params)
    .then((place) => { commit('SET_RESOURCE', place); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, {place, image}) {
    return service.upload(place, image)
      .then((image_path) => {
        commit('SET_IMAGE_PATH', image_path);
      });
  },

  get_with_features({commit, dispatch}, params) {
    return service.get_with_features(params)
      .then((place) => { commit('SET_RESOURCE', place); commit('SET_FEATURES', place.place_features); });
  },

  get_with_photos({commit, dispatch}, params) {
    return service.get_with_photos(params)
      .then((place) => { commit('SET_RESOURCE', place); commit('SET_PHOTOS', place.place_photos); });
  },

  edit_photo({commit, dispatch}, params) {
    return service.edit_photo(params);
  },

  destroy_photo({commit, dispatch}, params) {
    return service.destroy_photo(params);
  },  

  getTracksForEdit({commit, dispatch}, placeId) {
    return service.getTracksForEdit(placeId)
      .then((tracks) => { commit('SET_TRACKS', tracks); });
  },

  updateTracks({commit, dispatch}, { place, place_tracks }) {
    return service.updateTracks(place, place_tracks);
  },

  get_translations({commit, dispatch}, placeId) {
    return service.get_translations(placeId)
    .then((place) => { commit('SET_RESOURCE', place); commit('SET_TRANSLATIONS', place.meta.translations); });
  },

  updateTranslations({commit, dispatch}, { place, translations }) {
    return service.updateTranslations(place, translations);
  },

  get_place_types({commit, dispatch}, params) {
    return service.get_place_types(params)
      .then((place_types) => { commit('SET_PLACE_TYPES', place_types); });
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  place: state => state.place,
  url: state => state.url,
  image_path: state => state.image_path,
  features: state => state.features,
  photos: state => state.photos,
  tracks: state => state.tracks,
  translations: state => state.translations,
  place_types: state => state.place_types
};

const places = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default places;
