import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/banners`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/banners/${id}`, options)
    .then(response => {
      let banner = jsona.deserialize(response.data);
      delete banner.links;
      return banner;
    });
}

function add(banner) {
  const payload = jsona.serialize({
    stuff: banner,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/banners`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(banner) {
  const payload = jsona.serialize({
    stuff: banner,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/banners/${banner.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/banners/${id}`, options);
}

function upload(banner, image) {
  const payload = new FormData();
  payload.append('attachment', image);
  payload.append('has_thumb', '400|400');

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/uploads/banners/${banner.id}/image`, payload, options)
    .then(response => {
      return response.data.image_path;
    });
}

function getTracksForEdit(bannerId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  let tracks_url = bannerId ? `${url}/banner_tracks/banner/${bannerId}/edit` : `${url}/banner_tracks`;
  return axios.get(tracks_url, options)
    .then(response => {
    	return response.data.tracks;
    });
}

function updateTracks(banner, banner_tracks) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  return axios.post(`${url}/banner_tracks/update_tracks/${banner.id}`, { tracks: banner_tracks }, options);
}

function get_translations(bannerId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/banners/${bannerId}?with_translations=1`)
    .then(response => {
        let banner = jsona.deserialize(response.data);
        delete banner.links;
        return banner;
    });
}

function updateTranslations(banner, translations) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/update_translations`, { translations: translations, entity_type:'banner', entity_id:banner.id }, options);
}

function uploadTranslatedImage(banner, image, langCode) {
  const payload = new FormData();
  payload.append('attachment', image);
  payload.append('has_thumb', '400|400');
  payload.append('lang_code', langCode);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/uploads/banners/${banner.id}/image-translated`, payload, options)
    .then(response => {
      return response.data.image_path;
    });
}


export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  getTracksForEdit,
  updateTracks,
  get_translations,
  updateTranslations,
  uploadTranslatedImage
};
