<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Vis. homepage</p>
          <h3 class="title">
            <animated-number :value="h24HomeView"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            Ultime 24 ore
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Clic banner</p>
          <h3 class="title">
            <animated-number :value="h24BannerClick"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            Ultime 24 ore
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Vis. aziende</p>
          <h3 class="title">
            <animated-number :value="h24CompanyView"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            Ultime 24 ore
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Vis. itinerari</p>
          <h3 class="title">
            <animated-number :value="h24TourView"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            Ultime 24 ore
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
      <stats-card header-color="grey">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Vis. luoghi</p>
          <h3 class="title">
            <animated-number :value="h24PlaceView"></animated-number>
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>date_range</md-icon>
            Ultime 24 ore
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card
        header-animation="false"
        :chart-data="recentStatsHomeChart.chartData"
        :chart-options="recentStatsHomeChart.chartOptions"
        :chart-responsive-options="recentStatsHomeChart.chartResponsiveOptions"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="blue"
      >
        <template slot="content">
          <h4 class="title">Visite Home</h4>
          <p class="category">Conteggio aperture homepage dell'app</p>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="0"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card
        header-animation="false"
        :chart-data="recentStatsBannerClickChart.chartData"
        :chart-options="recentStatsBannerClickChart.chartOptions"
        :chart-responsive-options="recentStatsBannerClickChart.chartResponsiveOptions"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="rose"
      >
        <template slot="content">
          <h4 class="title">Clic banner</h4>
          <p class="category">Conteggio clic banner dell'app</p>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="0"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card
        header-animation="false"
        :chart-data="recentStatsBannerViewChart.chartData"
        :chart-options="recentStatsBannerViewChart.chartOptions"
        :chart-responsive-options="recentStatsBannerViewChart.chartResponsiveOptions"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="rose"
      >
        <template slot="content">
          <h4 class="title">Visualizzazioni banner</h4>
          <p class="category">Conteggio visualizzazioni banner dell'app</p>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="0"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card
        header-animation="false"
        :chart-data="recentStatsCompanyViewChart.chartData"
        :chart-options="recentStatsCompanyViewChart.chartOptions"
        :chart-responsive-options="recentStatsCompanyViewChart.chartResponsiveOptions"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="green"
      >
        <template slot="content">
          <h4 class="title">Visualizzazioni aziende</h4>
          <p class="category">Conteggio visualizzazioni aziende dell'app</p>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="0"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card
        header-animation="false"
        :chart-data="recentStatsTourViewChart.chartData"
        :chart-options="recentStatsTourViewChart.chartOptions"
        :chart-responsive-options="recentStatsTourViewChart.chartResponsiveOptions"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="warning"
      >
        <template slot="content">
          <h4 class="title">Visualizzazioni itinerari</h4>
          <p class="category">Conteggio visualizzazioni itinerari dell'app</p>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="0"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card
        header-animation="false"
        :chart-data="recentStatsTourNavigateChart.chartData"
        :chart-options="recentStatsTourNavigateChart.chartOptions"
        :chart-responsive-options="recentStatsTourNavigateChart.chartResponsiveOptions"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="warning"
      >
        <template slot="content">
          <h4 class="title">Realtime itinerari</h4>
          <p class="category">Conteggio realtime itinerari dell'app</p>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="0"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33">
      <chart-card
        header-animation="false"
        :chart-data="recentStatsPlaceViewChart.chartData"
        :chart-options="recentStatsPlaceViewChart.chartOptions"
        :chart-responsive-options="recentStatsPlaceViewChart.chartResponsiveOptions"
        chart-type="Bar"
        chart-inside-header
        no-footer
        background-color="grey"
      >
        <template slot="content">
          <h4 class="title">Visualizzazioni luoghi</h4>
          <p class="category">Conteggio visualizzazioni luoghi dell'app</p>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated <animated-number :value="0"></animated-number> days ago
          </div>
        </template>
      </chart-card>
    </div>

    <div class="md-layout-item md-size-100" v-show="0">
      <global-sales-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>language</md-icon>
          </div>
          <h4 class="title">Global Sales by Top Locations</h4>
        </template>

        <template slot="content">
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <global-sales-table></global-sales-table>
            </div>
            <div class="md-layout-item md-size-50">
              <async-world-map class="map" :data="mapData"></async-world-map>
            </div>
          </div>
        </template>
      </global-sales-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33" v-show="0">
      <product-card header-animation="true">
        <img class="img" slot="imageHeader" :src="product1" />
        <md-icon slot="fixed-button">build</md-icon>
        <template slot="first-button">
          <md-icon>art_track</md-icon>
          <md-tooltip md-direction="bottom">View</md-tooltip>
        </template>
        <template slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Edit</md-tooltip>
        </template>
        <template slot="third-button">
          <md-icon>close</md-icon>
          <md-tooltip md-direction="bottom">Remove</md-tooltip>
        </template>
        <h4 slot="title" class="title">
          <a href="#pablo">Cozy 5 Stars Apartment</a>
        </h4>
        <div slot="description" class="card-description">
          The place is close to Barceloneta Beach and bus stop just 2 min by
          walk and near to "Naviglio" where you can enjoy the main night life in
          Barcelona.
        </div>
        <template slot="footer">
          <div class="price">
            <h4>$899/night</h4>
          </div>
          <div class="stats">
            <p class="category">
              <md-icon>place</md-icon>
              Barcelona, Spain
            </p>
          </div>
        </template>
      </product-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33" v-show="0">
      <product-card header-animation="true">
        <img class="img" slot="imageHeader" :src="product2" />
        <md-icon slot="fixed-button">build</md-icon>
        <template slot="first-button">
          <md-icon>art_track</md-icon>
          <md-tooltip md-direction="bottom">View</md-tooltip>
        </template>
        <template slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Edit</md-tooltip>
        </template>
        <template slot="third-button">
          <md-icon>close</md-icon>
          <md-tooltip md-direction="bottom">Remove</md-tooltip>
        </template>
        <h4 slot="title" class="title">
          <a href="#pablo">Office Studio</a>
        </h4>
        <div slot="description" class="card-description">
          The place is close to Metro Station and bus stop just 2 min by walk
          and near to "Naviglio" where you can enjoy the night life in London,
          UK.
        </div>
        <template slot="footer">
          <div class="price">
            <h4>$1.119/night</h4>
          </div>
          <div class="stats">
            <p class="category">
              <md-icon>place</md-icon>
              London, UK
            </p>
          </div>
        </template>
      </product-card>
    </div>

    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33" v-show="0">
      <product-card header-animation="true">
        <img class="img" slot="imageHeader" :src="product3" />
        <md-icon slot="fixed-button">build</md-icon>
        <template slot="first-button">
          <md-icon>art_track</md-icon>
          <md-tooltip md-direction="bottom">View</md-tooltip>
        </template>
        <template slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">Edit</md-tooltip>
        </template>
        <template slot="third-button">
          <md-icon>close</md-icon>
          <md-tooltip md-direction="bottom">Remove</md-tooltip>
        </template>
        <h4 slot="title" class="title">
          <a href="#pablo">Beautiful Castle</a>
        </h4>
        <div slot="description" class="card-description">
          The place is close to Metro Station and bus stop just 2 min by walk
          and near to "Naviglio" where you can enjoy the main night life in
          Milan.
        </div>
        <template slot="footer">
          <div class="price">
            <h4>$459/night</h4>
          </div>
          <div class="stats">
            <p class="category">
              <md-icon>place</md-icon>
              Milan, Italy
            </p>
          </div>
        </template>
      </product-card>
    </div>
  </div>
</template>

<script>
import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import {
  StatsCard,
  ChartCard,
  ProductCard,
  AnimatedNumber,
  GlobalSalesCard,
  GlobalSalesTable
} from "@/components";

export default {
  components: {
    StatsCard,
    ChartCard,
    AnimatedNumber,
    ProductCard,
    GlobalSalesCard,
    GlobalSalesTable,
    AsyncWorldMap
  },
  data() {
    return {
      h24BannerClick: null,
	  h24BannerView: null,
	  h24CompanyView: null,
	  h24HomeView: null,
	  h24OfferView: null,
      h24PlaceView: null,
	  h24TourNavigate: null,
	  h24TourView: null,
      product1: process.env.VUE_APP_BASE_URL + "/img/card-2.jpg",
      product2: process.env.VUE_APP_BASE_URL + "/img/card-3.jpg",
      product3: process.env.VUE_APP_BASE_URL + "/img/card-1.jpg",
      seq2: 0,
      mapData: {
        AU: 760,
        BR: 550,
        CA: 120,
        DE: 1300,
        FR: 540,
        GB: 690,
        GE: 200,
        IN: 200,
        RO: 600,
        RU: 300,
        US: 2920
      },
      chartSettings: {
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high as the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          axisX: {
            scaleMinSpace: 100,
            showLabel: false,
            showGrid: false,
            onlyInteger: true,
            offset: 20,
            //labelInterpolationFnc: function(value, idx) { console.log(value, idx); return value[0]; }
            labelInterpolationFnc: (value, index) => (index % 2 === 0 ? value : null)
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5
            }
          ]
        ]
      },
      recentStatsBannerClickChart: {},
      recentStatsBannerViewChart: {},
      recentStatsCompanyViewChart: {},
      recentStatsHomeChart: {},
      recentStatsOfferViewChart: {},
      recentStatsPlaceViewChart: {},
      recentStatsTourNavigateChart: {},
      recentStatsTourViewChart: {}
    };
  },

  created() {
      this.getH24Stats();
      this.getRecentStats();
  },

  methods: {
      async getH24Stats() {
        await this.$store.dispatch("stats/getH24");
        let h24Stats = await this.$store.getters["stats/getH24"];

        for (var k in h24Stats) {
            const item = h24Stats[k];

            switch (item.item_key) {
                case 'banner.click':
                    this.h24BannerClick = item.num; break;

	            case 'banner.view':
	                this.h24BannerView = item.num; break;

	            case 'company.view':
	                this.h24CompanyView = item.num; break;

	            case 'home':
	                this.h24HomeView = item.num; break;

	            case 'offer.view':
	                this.h24OfferView = item.num; break;

	            case 'tour.navigate':
	                this.h24TourNavigate = item.num; break;

	            case 'tour.view':
	                this.h24TourView = item.num; break;

	            case 'place.view':
	                this.h24PlaceView = item.num; break;
            }
		}
      },

      async getRecentStats() {
        await this.$store.dispatch("stats/getRecent", { months: 1 });
        let recentStats = await this.$store.getters["stats/getRecent"];

		let chartData = {
            'banner.click': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions },
	        'banner.view': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions },
            'company.view': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions },
	        'home': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions },
            'offer.view': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions },
            'place.view': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions },
            'tour.navigate': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions },
            'tour.view': { chartData: { labels: [], series: [] }, chartOptions: this.chartSettings.options, chartResponsiveOptions: this.chartSettings.responsiveOptions }
		}

		for (var k in chartData) {
            chartData[k].chartData.series.push([]);
		}

        for (var k in recentStats) {
            const item = recentStats[k];
            const day_parts = item.day.split('-');

            chartData[item.item_key].chartData.labels.push(day_parts[2] + '/' + day_parts[1]);
		    chartData[item.item_key].chartData.series[0].push(item.num);
            chartData[item.item_key].chartOptions.low = Math.min(item.num, chartData[item.item_key].chartOptions.low);
		    chartData[item.item_key].chartOptions.high = Math.max(item.num, chartData[item.item_key].chartOptions.high);
        }

        for (var k in chartData) {
            chartData[k].chartOptions.high *= 1.1;
        }

        this.recentStatsBannerClickChart = chartData['banner.click'];
        this.recentStatsBannerViewChart = chartData['banner.view'];
        this.recentStatsCompanyViewChart = chartData['company.view'];
        this.recentStatsHomeChart = chartData['home'];
        this.recentStatsOfferViewChart = chartData['offer.view'];
        this.recentStatsPlaceViewChart = chartData['place.view'];
        this.recentStatsTourNavigateChart = chartData['tour.navigate'];
        this.recentStatsTourViewChart = chartData['tour.view'];
      }
  }
};
</script>
