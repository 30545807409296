import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Example pages
import UserProfile from "@/pages/Dashboard/Examples/UserProfile.vue";

// User Management
import ListUserPage from "@/pages/Dashboard/Examples/UserManagement/ListUserPage.vue";
import AddUserPage from "@/pages/Dashboard/Examples/UserManagement/AddUserPage.vue";
import EditUserPage from "@/pages/Dashboard/Examples/UserManagement/EditUserPage.vue";

// User Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";

// TurismoVittorioVeneto Pages
import ListCompaniesPage from "@/pages/TurismoVittorioVeneto/Companies/ListCompaniesPage.vue";
import AddCompanyPage from "@/pages/TurismoVittorioVeneto/Companies/AddCompanyPage.vue";
import EditCompanyPage from "@/pages/TurismoVittorioVeneto/Companies/EditCompanyPage.vue";
import EditCompanyFeaturesPage from "@/pages/TurismoVittorioVeneto/Companies/EditCompanyFeaturesPage.vue";
import EditCompanyPhotosPage from "@/pages/TurismoVittorioVeneto/Companies/EditCompanyPhotosPage.vue";
import EditCompanyReservationsPage from "@/pages/TurismoVittorioVeneto/Companies/EditCompanyReservationsPage.vue";
import EditCompanyTranslationsPage from "@/pages/TurismoVittorioVeneto/Companies/EditCompanyTranslationsPage.vue";
import ListOffersPage from "@/pages/TurismoVittorioVeneto/Offers/ListOffersPage.vue";
import EditOfferPage from "@/pages/TurismoVittorioVeneto/Offers/EditOfferPage.vue";
import EditOfferTranslationsPage from "@/pages/TurismoVittorioVeneto/Offers/EditOfferTranslationsPage.vue";
import AddOfferPage from "@/pages/TurismoVittorioVeneto/Offers/AddOfferPage.vue";
import ListBannersPage from "@/pages/TurismoVittorioVeneto/Banners/ListBannersPage.vue";
import EditBannerPage from "@/pages/TurismoVittorioVeneto/Banners/EditBannerPage.vue";
import EditBannerTranslationsPage from "@/pages/TurismoVittorioVeneto/Banners/EditBannerTranslationsPage.vue";
import AddBannerPage from "@/pages/TurismoVittorioVeneto/Banners/AddBannerPage.vue";
import ListTracksPage from "@/pages/TurismoVittorioVeneto/Tracks/ListTracksPage.vue";
import AddTrackPage from "@/pages/TurismoVittorioVeneto/Tracks/AddTrackPage.vue";
import EditTrackPage from "@/pages/TurismoVittorioVeneto/Tracks/EditTrackPage.vue";
import EditTrackFeaturesPage from "@/pages/TurismoVittorioVeneto/Tracks/EditTrackFeaturesPage.vue";
import EditTrackPhotosPage from "@/pages/TurismoVittorioVeneto/Tracks/EditTrackPhotosPage.vue";
import EditTrackPointsPage from "@/pages/TurismoVittorioVeneto/Tracks/EditTrackPointsPage.vue";
import EditTrackTranslationsPage from "@/pages/TurismoVittorioVeneto/Tracks/EditTrackTranslationsPage.vue";
import ListMapPointsPageBusiness from "@/pages/TurismoVittorioVeneto/MapPoints/ListMapPointsPageBusiness.vue";
import ListMapPointsPageService from "@/pages/TurismoVittorioVeneto/MapPoints/ListMapPointsPageService.vue";
import AddMapPointPage from "@/pages/TurismoVittorioVeneto/MapPoints/AddMapPointPage.vue";
import EditMapPointPage from "@/pages/TurismoVittorioVeneto/MapPoints/EditMapPointPage.vue";
import ListPlacesPage from "@/pages/TurismoVittorioVeneto/Places/ListPlacesPage.vue";
import AddPlacePage from "@/pages/TurismoVittorioVeneto/Places/AddPlacePage.vue";
import EditPlacePage from "@/pages/TurismoVittorioVeneto/Places/EditPlacePage.vue";
import EditPlaceFeaturesPage from "@/pages/TurismoVittorioVeneto/Places/EditPlaceFeaturesPage.vue";
import EditPlacePhotosPage from "@/pages/TurismoVittorioVeneto/Places/EditPlacePhotosPage.vue";
import EditPlaceReservationsPage from "@/pages/TurismoVittorioVeneto/Places/EditPlaceReservationsPage.vue";
import EditPlaceTranslationsPage from "@/pages/TurismoVittorioVeneto/Places/EditPlaceTranslationsPage.vue";

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

let userMenu = {
	  path: "/user",
	  component: DashboardLayout,
	  name: "User",
	  children: [
	    {
	      path: "account",
	      name: "user.account",
	      components: { default: UserProfile },
	      meta: { middleware: auth }
	    },
    ]
};

let adminMenu = {
	path: "/manage",
	component: DashboardLayout,
	name: "Manage",
	redirect: "/manage/users/list",
	children: [
	    {
	      path: "users/list",
	      name: "users.list_users",
	      components: { default: ListUserPage },
	      meta: { middleware: auth }
	    },
	    {
	      path: "users/add",
	      name: "users.add_user",
	      components: { default: AddUserPage },
	      meta: { middleware: auth }
	    },
	    {
	      path: "users/edit/:id",
	      name: "users.edit_user",
	      components: { default: EditUserPage },
	      meta: { middleware: auth }
	    }
    ]
};

let companiesMenu = {
  path: "/manage",
  component: DashboardLayout,
  name: "Companies",
  redirect: "/manage/companies/list",
  children: [
    {
      path: "companies/list",
      name: "companies.list_companies",
      components: { default: ListCompaniesPage },
      meta: { middleware: auth }
    },
    {
      path: "companies/add",
      name: "companies.add_company",
      components: { default: AddCompanyPage },
      meta: { middleware: auth }
    },
    {
      path: "companies/edit/:id",
      name: "companies.edit_company",
      components: { default: EditCompanyPage },
      meta: { middleware: auth }
    },
    {
      path: "companies/edit/:id/features",
      name: "companies.edit_company_features",
      components: { default: EditCompanyFeaturesPage },
      meta: { middleware: auth }
    },
    {
      path: "companies/update_features/:id",
      name: "companies.update_company_features",
      meta: { middleware: auth }
    },
    {
      path: "companies/edit/:id/photos",
      name: "companies.edit_company_photos",
      components: { default: EditCompanyPhotosPage },
      meta: { middleware: auth }
    },
    {
      path: "companies/edit/:id/reservations",
      name: "companies.edit_reservations",
      components: { default: EditCompanyReservationsPage },
      meta: { middleware: auth }
    },
    {
      path: "companies/edit/:id/translations",
      name: "companies.edit_translations",
      components: { default: EditCompanyTranslationsPage },
      meta: { middleware: auth }
    }
  ]
};

let tracksMenu = {
  path: "/manage",
  component: DashboardLayout,
  name: "Trcks",
  redirect: "/manage/tracks/list",
  children: [
    {
      path: "tracks/list",
      name: "tracks.list_tracks",
      components: { default: ListTracksPage },
      meta: { middleware: auth }
    },
    {
      path: "tracks/add",
      name: "tracks.add_track",
      components: { default: AddTrackPage },
      meta: { middleware: auth }
    },
    {
      path: "tracks/edit/:id",
      name: "tracks.edit_track",
      components: { default: EditTrackPage },
      meta: { middleware: auth }
    },
    {
      path: "tracks/edit/:id/features",
      name: "tracks.edit_track_features",
      components: { default: EditTrackFeaturesPage },
      meta: { middleware: auth }
    },
    {
      path: "tracks/update_features/:id",
      name: "tracks.update_track_features",
      meta: { middleware: auth }
    },
    {
      path: "tracks/edit/:id/photos",
      name: "tracks.edit_track_photos",
      components: { default: EditTrackPhotosPage },
      meta: { middleware: auth }
    },
    {
      path: "tracks/edit/:id/points",
      name: "tracks.edit_track_points",
      components: { default: EditTrackPointsPage },
      meta: { middleware: auth }
    },
    {
      path: "tracks/edit/:id/translations",
      name: "tracks.edit_translations",
      components: { default: EditTrackTranslationsPage },
      meta: { middleware: auth }
    }
  ]
};

let offersMenu = {
  path: "/manage",
  component: DashboardLayout,
  name: "Offers",
  redirect: "/manage/offers/list",
  children: [
    {
      path: "offers/list",
      name: "offers.list_offers",
      components: { default: ListOffersPage },
      meta: { middleware: auth }
    },
    {
        path: "offers/list/company/:id",
        name: "offers.list_offers_company",
        components: { default: ListOffersPage },
        meta: { middleware: auth }
    },
    {
      path: "offers/add",
      name: "offers.add_offer",
      components: { default: AddOfferPage },
      meta: { middleware: auth }
    },
    {
      path: "offers/edit/:id",
      name: "offers.edit_offer",
      components: { default: EditOfferPage },
      meta: { middleware: auth }
    },
    {
      path: "offers/edit/:id/translations",
      name: "offers.edit_translations",
      components: { default: EditOfferTranslationsPage },
      meta: { middleware: auth }
    }
  ]
};

let bannersMenu = {
  path: "/manage",
  component: DashboardLayout,
  name: "Banners",
  redirect: "/manage/banners/list",
  children: [
    {
      path: "banners/list",
      name: "banners.list_banners",
      components: { default: ListBannersPage },
      meta: { middleware: auth }
    },
    {
      path: "banners/add",
      name: "banners.add_banner",
      components: { default: AddBannerPage },
      meta: { middleware: auth }
    },
    {
      path: "banners/edit/:id",
      name: "banners.edit_banner",
      components: { default: EditBannerPage },
      meta: { middleware: auth }
    },
    {
      path: "banners/edit/:id/translations",
      name: "banners.edit_translations",
      components: { default: EditBannerTranslationsPage },
      meta: { middleware: auth }
    }
  ]
};

let mapPointsMenu = {
  path: "/manage",
  component: DashboardLayout,
  name: "Map Points",
  redirect: "/manage/map_points/list_business",
  children: [
    {
      path: "map_points/list_business",
      name: "map_points.list_business_map_points",
      components: { default: ListMapPointsPageBusiness },
      meta: { middleware: auth }
    },
    {
      path: "map_points/list_service",
      name: "map_points.list_service_map_points",
      components: { default: ListMapPointsPageService },
      meta: { middleware: auth }
    },
    {
      path: "map_points/add",
      name: "map_points.add_map_points",
      components: { default: AddMapPointPage },
      meta: { middleware: auth }
    },
    {
      path: "map_points/edit/:id",
      name: "map_points.edit_map_points",
      components: { default: EditMapPointPage },
      meta: { middleware: auth }
    }
  ]
};

let examplesMenu = {
  path: "/examples",
  component: DashboardLayout,
  name: "Examples",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: auth }
    }
  ]
};

let placesMenu = {
  path: "/manage",
  component: DashboardLayout,
  name: "Places",
  redirect: "/manage/places/list",
  children: [
    {
      path: "places/list",
      name: "places.list_places",
      components: { default: ListPlacesPage },
      meta: { middleware: auth }
    },
    {
      path: "places/add",
      name: "places.add_place",
      components: { default: AddPlacePage },
      meta: { middleware: auth }
    },
    {
      path: "places/edit/:id",
      name: "places.edit_place",
      components: { default: EditPlacePage },
      meta: { middleware: auth }
    },
    {
      path: "places/edit/:id/features",
      name: "places.edit_place_features",
      components: { default: EditPlaceFeaturesPage },
      meta: { middleware: auth }
    },
    {
      path: "places/update_features/:id",
      name: "places.update_place_features",
      meta: { middleware: auth }
    },
    {
      path: "places/edit/:id/photos",
      name: "places.edit_place_photos",
      components: { default: EditPlacePhotosPage },
      meta: { middleware: auth }
    },
    {
      path: "places/edit/:id/reservations",
      name: "places.edit_reservations",
      components: { default: EditPlaceReservationsPage },
      meta: { middleware: auth }
    },
    {
      path: "places/edit/:id/translations",
      name: "places.edit_translations",
      components: { default: EditPlaceTranslationsPage },
      meta: { middleware: auth }
    }
  ]
};

/*
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
      meta: { middleware: auth }
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
      meta: { middleware: auth }
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
      meta: { middleware: auth }
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
      meta: { middleware: auth }
    }
  ]
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
      meta: { middleware: auth }
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
      meta: { middleware: auth }
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables },
      meta: { middleware: auth }
    }
  ]
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
      meta: { middleware: auth }
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
        middleware: auth
      },
      components: { default: FullScreenMap }
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
      meta: { middleware: auth }
    }
  ]
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User },
      meta: { middleware: auth }
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
      meta: { middleware: auth }
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
        middleware: auth
      },
      components: { default: RtlSupport }
    }
  ]
};
*/

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest }
    }
    /*
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock
    }
    */
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  userMenu,
  adminMenu,
  companiesMenu,
  offersMenu,
  bannersMenu,
  tracksMenu,
  mapPointsMenu,
  placesMenu,
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    meta: { middleware: auth },
    children: [
      {
        path: "dashboard",
        name: "home",
        components: { default: Dashboard },
        meta: { middleware: auth }
      }
    ]
  }
];

export default routes;
