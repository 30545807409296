<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('tracks.edit_track_points') }}</h4>
        </md-card-header>
   
          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ track.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('tracks.back_track') }}
                  </md-button>
                </div>
            </div>

            <div class="md-layout">
                <md-field>
                    <md-file @change="selectPointsFile" :placeholder="this.$t('tracks.upload_points')" accept="application/gpx+xml" />
                    <progress max="100" :value.prop="uploadPercentage" v-if="uploadPercentage > 0"></progress>
                </md-field>
            </div>
          
            <div class="md-layout">
                <div id="map"></div>
            </div>
          </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  mixins: [formMixin],

  data: () => ({
    track: {
      type: "tracks",
      name: null,
      email: null,
      latitude: null,
      longitude: null
    },
    points: [],
    uploadPercentage: 0,
    uploadUrl: '',
    kmlLayer: null
  }),

  created() {
    this.getList();
  },

  methods: {
    async getList() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("tracks/get_with_points", id);
        this.track = this.$store.getters["tracks/track"];
        this.points = this.track.track_points;
        this.uploadUrl = process.env.VUE_APP_API_BASE_URL + '/upload/track/points/' + this.track.id;
        this.initMap();
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    selectPointsFile(event) {
        let file = event.target.files[0];

        let formData = new FormData();
        formData.append('file', file);

        axios.post(this.uploadUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            },
        })
        .then((response) => {
            this.uploadPercentage = 0;

            // update map
            this.refreshKml(this.getKmlUrl());
            //this.refreshKml('https://www.cercoinzona.it/1028_l3nu7tdiv_Alt_Portsmouth.kml');
        });
    },

    async destroy(photo_id) {
      const confirmation = await Swal.fire({
        title: this.$t('tracks.delete_photo_confirmation_request'),
        type: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('yes_delete'),
        cancelButtonText: this.$t('no_cancel'),
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
      });

      if (confirmation.value === true) {
        try {
          await this.$store.dispatch("tracks/destroy_photo", { track_id: this.track.id, photo_id: photo_id });
          await this.$store.dispatch(
            "alerts/success",
            this.$t('message.delete_success')
          );
          await this.getList(false);
        } catch (e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              "alerts/error",
              e.response.data.errors[0].title
            );
          } else {
            await this.$store.dispatch(
              "alerts/error",
              this.$t('message.error_generic')
            );
          }
        }
      }
    },

    initMap() {
        var self = this;

        this.$gmapLoader.load().then(function(google) {
            const myLatLng = new google.maps.LatLng(self.track.latitude || process.env.VUE_APP_DEFAULT_LATITUDE, self.track.longitude || process.env.VUE_APP_DEFAULT_LONGITUDE);
            let mapOptions = {
                zoom: 13,
                center: myLatLng,
                scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
                disableDefaultUI: false, // a way to quickly hide all controls
                zoomControl: true
            }
            const map = new google.maps.Map(
                document.getElementById("map"),
                mapOptions
            );

            self.kmlLayer = new google.maps.KmlLayer({
                map: map,
                url: self.getKmlUrl()
            });
        });
    },

	getKmlUrl() {
		//return 'https://appturismovittorioveneto.com/kml/track/1.kml';
		return process.env.VUE_APP_SERVER_BASE_URL + '/kml/track/' + this.track.id + '.kml?_t=' + (new Date()).getTime();
	},

    refreshKml(url) {
    	console.log(url);
    	const map = this.kmlLayer.map;
    	this.kmlLayer.setMap(null);
    	this.kmlLayer.url = url;
    	this.kmlLayer.setMap(map);
    },

    goBack() {
      this.$router.push({ name: "tracks.edit_track", id: this.track.id }).catch(err => {});
    }
  }
};
</script>

<style lang="scss">
    #map { height:500px; width:100%; margin:0 auto; }
</style>