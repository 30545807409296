import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/tracks`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/tracks/${id}`, options)
    .then(response => {
      let track = jsona.deserialize(response.data);
      delete track.links;
      return track;
    });
}

function get_with_features(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/tracks/${id}?include=track_features`, options)
    .then(response => {
      let track = jsona.deserialize(response.data);
      delete track.links;
      return track;
    });
}

function get_with_photos(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/tracks/${id}?include=track_photos`, options)
    .then(response => {
      let track = jsona.deserialize(response.data);
      delete track.links;
      return track;
    });
}

function get_with_points(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/tracks/${id}?include=track_points`, options)
    .then(response => {
      let track = jsona.deserialize(response.data);
      delete track.links;
      return track;
    });
}

function add(track) {
  const payload = jsona.serialize({
    stuff: track,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/tracks`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(track) {
  const payload = jsona.serialize({
    stuff: track,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/tracks/${track.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/tracks/${id}`, options);
}

function upload(track, image) {
  const payload = new FormData();
  payload.append('attachment', image);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/uploads/tracks/${track.id}/image`, payload, options)
    .then(response => {
      return response.data.image_path;
    });
}

function update_features(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/save/track/${params.track.id}/features`, { features: params.features }, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update_photos(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/save/track/${params.track.id}/photos`, params.photos, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function edit_photo(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.put(`${url}/edit/track_photo/${params.track_id}/${params.photo.id}`, params.photo, options);
}

function destroy_photo(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/delete/track_photo/${params.track_id}/${params.photo_id}`, options);
}

function get_translations(trackId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/tracks/${trackId}?with_translations=1`)
    .then(response => {
        let track = jsona.deserialize(response.data);
        delete track.links;
        return track;
    });
}

function updateTranslations(track, translations) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/update_translations`, { translations: translations, entity_type:'track', entity_id:track.id }, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  get_with_features,
  update_features,
  get_with_photos,
  update_photos,
  edit_photo,
  destroy_photo,
  get_with_points,
  get_translations,
  updateTranslations
};