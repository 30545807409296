<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('companies.edit_company_photos') }}</h4>
        </md-card-header>
   
          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ company.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('companies.back_company') }}
                  </md-button>
                </div>
            </div>

            <div class="md-layout">
                <md-field>
                    <md-file @change="selectFiles" :placeholder="this.$t('companies.upload_photos')" multiple accept="image/*" />
                    <progress max="100" :value.prop="uploadPercentage" v-if="uploadPercentage > 0"></progress>
                </md-field>
            </div>

            <md-table :value="photos">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell :md-label="$t('image')">
                        <img :src="item.thumb" class="thumb" />
                    </md-table-cell>
                    <md-table-cell :md-label="$t('title')">
                        <md-field> 
                            <md-input v-model="item.title" @blur="editPhoto(item)" />
                        </md-field>
                    </md-table-cell>
                    <md-table-cell :md-label="$t('date.created')" md-sort-by="created_at">
                        {{ item.created_at|moment('DD/MM/YYYY HH:mm') }}
                    </md-table-cell>
                    <md-table-cell :md-label="$t('actions')">
                        <md-button
                          class="md-icon-button md-raised md-round md-danger"
                          @click="destroy(item.id)"
                          style="margin: 0.2rem"
                        >
                          <md-icon>delete</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>
          </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  mixins: [formMixin],

  data: () => ({
    company: {
      type: "companies",
      name: null,
      email: null,
      latitude: null,
      longitude: null
    },
    uploaderOptions: {
        className: 'btn-solid',
        btnContent: 'Click Me',
        url: process.env.VUE_APP_API_BASE_URL + '/upload/company/photos/',
        accept: 'image/*',
        multiple: true,
        autoStart: true,
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem("vue-authenticate.vueauth_access_token") }
    },
    photos: [],
    uploadPercentage: 0,
  }),

  created() {
    this.getList(true);
  },

  methods: {
    async getList(init) {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("companies/get_with_photos", id);
        this.company = this.$store.getters["companies/company"];
        this.photos = this.company.company_photos;
        if (init) this.uploaderOptions.url += this.company.id;
      } catch (e) {
      console.log(e);
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    selectFiles(event) {
        _.forEach(event.target.files, (file => {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('title', file.name);

            axios.post(this.uploaderOptions.url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                },
            })
            .then((response) => {
                let photo = response.data;
                this.photos.push(photo);
                this.uploadPercentage = 0;
            });
        }));
    },

    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    },

    async destroy(photo_id) {
      const confirmation = await Swal.fire({
        title: this.$t('companies.delete_photo_confirmation_request'),
        type: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('yes_delete'),
        cancelButtonText: this.$t('no_cancel'),
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
      });

      if (confirmation.value === true) {
        try {
          await this.$store.dispatch("companies/destroy_photo", { company_id: this.company.id, photo_id: photo_id });
          await this.$store.dispatch(
            "alerts/success",
            this.$t('message.delete_success')
          );
          await this.getList(false);
        } catch (e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              "alerts/error",
              e.response.data.errors[0].title
            );
          } else {
            await this.$store.dispatch(
              "alerts/error",
              this.$t('message.error_generic')
            );
          }
        }
      }
    },

    async editPhoto(item) {
        try {
            await this.$store.dispatch("companies/edit_photo",  { company_id: this.company.id, photo: item });
            await this.$store.dispatch(
                "alerts/success",
                this.$t('message.changes_save_success')
            );
        } catch(e) {
          if (e.response.data.errors[0]) {
            await this.$store.dispatch(
              "alerts/error",
              e.response.data.errors[0].title
            );
          } else {
            await this.$store.dispatch(
              "alerts/error",
              this.$t('message.error_generic')
            );
          }
        }
    },

    goBack() {
      this.$router.push({ name: "companies.edit_company", id: this.company.id }).catch(err => {});
    }
  }
};
</script>

<style lang="scss">
    .thumb { height: 100px !important; width: auto !important; }
</style>