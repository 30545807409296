<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('tracks.edit_track_features') }}</h4>
        </md-card-header>

        <form @submit.prevent="update_features">
          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ track.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('tracks.back_track') }}
                  </md-button>
                </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('tracks.difficulty') }}
              </label>
              <div class="md-layout-item">
                <md-radio v-model="features.difficulty" value="easy">{{ $t('track_feature.difficulty_easy') }}</md-radio>
                <md-radio v-model="features.difficulty" value="moderate">{{ $t('track_feature.difficulty_moderate') }}</md-radio>
                <md-radio v-model="features.difficulty" value="hard">{{ $t('track_feature.difficulty_hard') }}</md-radio>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('tracks.terrain') }}
              </label>
              <div class="md-layout-item">
                <md-checkbox v-model="features.terrain" value="asphalt">{{ $t('track_feature.terrain_asphalt') }}</md-checkbox>
                <md-checkbox v-model="features.terrain" value="dirt">{{ $t('track_feature.terrain_dirt') }}</md-checkbox>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('tracks.hike') }}
              </label>
              <div class="md-layout-item">
                  <md-checkbox v-model="features.hike" value="walking">{{ $t('track_feature.hike_hiking') }}</md-checkbox>
                  <md-checkbox v-model="features.hike" value="nordic_walking">{{ $t('track_feature.hike_nordic_walking') }}</md-checkbox>
                  <md-checkbox v-model="features.hike" value="running">{{ $t('track_feature.hike_trail_running') }}</md-checkbox>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('tracks.bike') }}
              </label>
              <div class="md-layout-item">
                  <md-checkbox v-model="features.bike" value="street">{{ $t('track_feature.bike_street') }}</md-checkbox>
                  <md-checkbox v-model="features.bike" value="mtb">{{ $t('track_feature.bike_mtb') }}</md-checkbox>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('tracks.track_theme') }}
              </label>
              <div class="md-layout-item">
                  <md-checkbox v-model="features.track_theme" value="culture">{{ $t('track_feature.track_theme_culture') }}</md-checkbox>
                  <md-checkbox v-model="features.track_theme" value="nature">{{ $t('track_feature.track_theme_nature') }}</md-checkbox>
                  <md-checkbox v-model="features.track_theme" value="history">{{ $t('track_feature.track_theme_history') }}</md-checkbox>
                  <md-checkbox v-model="features.track_theme" value="family">{{ $t('track_feature.track_theme_family') }}</md-checkbox>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('tracks.themed') }}
              </label>
              <div class="md-layout-item">
                <md-select v-model="features.themed">
                  <md-option value="">- {{ $t('track_feature.themed_none') }} -</md-option>
                  <md-option value="art_culture">{{ $t('track_feature.themed_art_culture') }}</md-option>
                  <md-option value="classic">{{ $t('track_feature.themed_classic') }}</md-option>
                  <md-option value="great_war">{{ $t('track_feature.themed_great_war') }}</md-option>
                  <md-option value="religious">{{ $t('track_feature.themed_religious') }}</md-option>
                </md-select>
              </div>
            </div>

          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button type="submit" class="md-success">{{ $t('action.save_changes') }}</md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  components: { },

  mixins: [formMixin],

  data: () => ({
    track: {
      type: "tracks",
      name: null,
      email: null,
      latitude: null,
      longitude: null
    },
    features: {}
  }),

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("tracks/get_with_features", id);
        this.track = this.$store.getters["tracks/track"];
        this.features = this.featuresList();
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    async update_features() {
      try {
        await this.$store.dispatch("tracks/update_features", { track: this.track, features: this.features });
        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );
        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            this.$t(e.response.data.errors[0].title) + (e.response.data.errors[0].detail ? ' ' + this.$t(e.response.data.errors[0].detail) : '')
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            "alerts/error",
            this.$t('message.error_generic')
          );
        }
      }
    },

    featuresList() {
      var myFeatures = this.getDefaultFeatures();

      for (var k in this.track.track_features) {
          const featureKey = this.track.track_features[k]['feature_key'];
          const featureValue = this.track.track_features[k]['feature_value'];

          if (Array.isArray(myFeatures[featureKey])) {
              myFeatures[featureKey].push(featureValue);
          }
          else {
              myFeatures[featureKey] = featureValue;
          }
      }

      return myFeatures;
    },

    goBack() {
      this.$router.push({ name: "tracks.edit_track", id: this.track.id }).catch(err => {});
    },

    getDefaultFeatures() {
      return {
          difficulty: null,
          terrain: [],
          hike: [],
          bike: [],
          track_theme: [],
          themed: [],
          signage: [],
          activities: []
      }
    }
  }
};
</script>

<style lang="scss">
.spacing { padding-bottom: 1rem; }
</style>
