import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/places`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/places/${id}`, options)
    .then(response => {
      let place = jsona.deserialize(response.data);
      delete place.links;
      return place;
    });
}

function add(place) {
  const payload = jsona.serialize({
    stuff: place,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/places`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(place) {
  const payload = jsona.serialize({
    stuff: place,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/places/${place.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/places/${id}`, options);
}

function upload(place, image) {
  const payload = new FormData();
  payload.append('attachment', image);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/uploads/places/${place.id}/image`, payload, options)
    .then(response => {
      return response.data.image_path;
    });
}

function get_with_features(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/places/${id}?include=place_features`, options)
    .then(response => {
      let place = jsona.deserialize(response.data);
      delete place.links;
      return place;
    });
}

function get_with_photos(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/places/${id}?include=place_photos`, options)
    .then(response => {
      let place = jsona.deserialize(response.data);
      delete place.links;
      return place;
    });
}

function update_features(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/save/place/${params.place.id}/features`, { features: params.features }, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update_photos(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/save/place/${params.place.id}/photos`, params.photos, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function edit_photo(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.put(`${url}/edit/place_photo/${params.place_id}/${params.photo.id}`, params.photo, options);
}

function destroy_photo(params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/delete/place_photo/${params.place_id}/${params.photo_id}`, options);
}

function getTracksForEdit(placeId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  let tracks_url = placeId ? `${url}/tracks_places/place/${placeId}/edit` : `${url}/tracks_places`;
  return axios.get(tracks_url, options)
    .then(response => {
    	return response.data.tracks;
    });
}

function updateTracks(place, place_tracks) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/tracks_places/update_tracks/${place.id}`, { tracks: place_tracks }, options);
}

function get_translations(placeId) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/places/${placeId}?with_translations=1`)
    .then(response => {
        let place = jsona.deserialize(response.data);
        delete place.links;
        return place;
    });
}

function updateTranslations(place, translations) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/update_translations`, { translations: translations, entity_type:'place', entity_id:place.id }, options);
}

function get_place_types() {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/place_types`, options)
    .then(response => {
      return response.data;
  });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  get_with_features,
  update_features,
  get_with_photos,
  update_photos,
  edit_photo,
  destroy_photo,
  getTracksForEdit,
  updateTracks,
  get_translations,
  updateTranslations,
  get_place_types
};
