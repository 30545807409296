import service from '@/store/services/offers-service';

const state = {
  list: {},
  offer: {},
  meta: {},
  url: null,
  image_path: null,
  tracks: [],
  translations: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, offer) => {
    state.offer = offer;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_IMAGE: (state, image_path) => {
    state.image_path = image_path;
  },
  SET_TRACKS: (state, tracks) => {
    state.tracks = tracks;
  },
  SET_TRANSLATIONS: (state, translations) => {
    state.translations = translations;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, params) {
    return service.get(params)
      .then((offer) => { commit('SET_RESOURCE', offer); });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((offer) => { commit('SET_RESOURCE', offer); });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((offer) => { commit('SET_RESOURCE', offer); });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, {offer, image}) {
    return service.upload(offer, image)
      .then((image_path) => {
        commit('SET_IMAGE', image_path);
      });
  },

  getTracksForEdit({commit, dispatch}, offerId) {
    return service.getTracksForEdit(offerId)
      .then((tracks) => { commit('SET_TRACKS', tracks); });
  },

  updateTracks({commit, dispatch}, { offer, offer_tracks }) {
    return service.updateTracks(offer, offer_tracks);
  },

  get_translations({commit, dispatch}, offerId) {
    return service.get_translations(offerId)
    .then((offer) => { commit('SET_RESOURCE', offer); commit('SET_TRANSLATIONS', offer.meta.translations); });
  },

  updateTranslations({commit, dispatch}, { offer, translations }) {
    return service.updateTranslations(offer, translations);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  offer: state => state.offer,
  url: state => state.url,
  image_path: state => state.image_path,
  tracks: state => state.tracks,
  translations: state => state.translations
};

const offers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default offers;
