<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('tracks.edit_translations') }}</h4>
        </md-card-header>

          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ track.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('tracks.back_track') }}
                  </md-button>
                </div>
            </div>

        	<form @submit.prevent="update">
	            <md-card v-for="(translated_strings, langCode) in translations" :key="langCode">
	            	<md-card-header>
	            		<div class="md-title">{{ $t('languages.code_' + langCode) }}</div>
	        		</md-card-header>

		            <div class="md-layout spacing">
		              <label class="md-layout-item md-size-25 md-form-label">
		                {{ $t('name') }}
		              </label>
		              <div class="md-layout-item">
		                <md-field class="md-invalid">
		                  <md-input v-model="translated_strings.name" />
		                </md-field>
		              </div>
		            </div>

		            <div class="md-layout spacing">
		              <label class="md-layout-item md-size-25 md-form-label">
		                {{ $t('description') }}
		              </label>
		              <div class="md-layout-item">
		                <md-field class="md-invalid">
		                  <md-textarea v-model="translated_strings.description"></md-textarea>
		                </md-field>
		              </div>
		            </div>
	            </md-card>

		          <md-card-actions md-alignment="right">
		            <md-button type="submit" class="md-success">{{ $t('action.save_changes') }}</md-button>
		          </md-card-actions>

            </form>
          </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  mixins: [formMixin],

  data: () => ({
    track: {
      type: "tracks",
      name: null,
      email: null,
      latitude: null,
      longitude: null
    },
    translations: {},
    languages: []
  }),

  created() {
    this.getTranslations();
  },

  methods: {
    async getTranslations() {
      try {
        const id = this.$route.params.id;
        const track_fields = [ 'name', 'description' ];

        await this.$store.dispatch("tracks/get_translations", id);
        this.track = this.$store.getters["tracks/track"];
        this.languages = this.$store.languages;
        this.translations = this.$store.$app.initTranslations(this.$store.getters["tracks/translations"], track_fields);
        console.log(this.translations);
      } catch (e) {
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    async update() {
        await this.$store.dispatch("tracks/updateTranslations", { track:this.track, translations:this.translations });
        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );
        this.goBack();
	},

    goBack() {
      this.$router.push({ name: "tracks.edit_track", id: this.track.id }).catch(err => {});
    }
  }
};
</script>