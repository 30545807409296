<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>contacts</md-icon>
          </div>
          <h4 class="title">{{ $t('companies.add_company') }}</h4>
        </md-card-header>

        <form @submit.prevent="add">
          <md-card-content>
            <div class="text-right">
              <md-button @click="goBack" class="md-primary md-dense">
                {{ $t('action.back_list') }}
              </md-button>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('image') }}
              </label>
              <div class="md-layout-item">
                <div class="file-input">
                  <div v-if="image">
                    <div class="image-container">
                      <img :src="image" />
                    </div>
                  </div>
                  <div class="image-container" v-else>
                    <img :src="default_img" />
                  </div>
                  <div class="button-container">
                    <md-button
                      class="md-danger md-round"
                      @click="removeImage"
                      v-if="image"
                    >
                      <i class="fa fa-times" />
                      {{ $t('action.delete') }}
                    </md-button>
                    <md-button class="md-success md-fileinput">
                      <template v-if="!image">{{ $t('action.select_image') }}</template>
                      <template v-else>{{ $t('action.change') }}</template>
                      <input type="file" @change="onFileChange" />
                    </md-button>
                  </div>
                </div>
                <md-field class="md-invalid">
                  <validation-error :errors="apiValidationErrors.attachment" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('name') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.name" />
                  <validation-error :errors="apiValidationErrors.name" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('email') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.email" />
                  <validation-error :errors="apiValidationErrors.email" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('status') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>{{ $t('select.placeholder') }}</label>
                  <md-select
                    v-if="available_statuses"
                    v-model="company.status"
                    name="status"
                  >
                    <md-option
                      v-for="item in available_statuses"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.label }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('phone_number') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.phone_number" />
                  <validation-error :errors="apiValidationErrors.phone_number" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('description') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-textarea v-model="company.description" required></md-textarea>
                  <validation-error :errors="apiValidationErrors.description" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('address.street') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.street" />
                  <validation-error :errors="apiValidationErrors.street" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('address.postcode') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.postcode" />
                  <validation-error :errors="apiValidationErrors.postcode" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('address.city') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.city" />
                  <validation-error :errors="apiValidationErrors.city" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('address.province') }}
              </label>
              <div class="md-layout-item">
                <md-field>
                  <label>{{ $t('select.placeholder') }}</label>
                  <md-select
                    v-model="company.province"
                    name="province"
                  >
                    <md-option
                      v-for="pname,pcode in provinces"
                      :key="pcode"
                      :value="pcode"
                    >
                      {{ pname }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('position') }}
              </label>
              <div class="md-layout-item" id="gmap-position">
                <div id="map"></div>
                <div id="coordinates">
                    <md-field>
                      <label>Latitude</label>
                      <md-input v-model="company.latitude" required />
                    </md-field>
                    <md-field>
                      <label>Longitude</label>
                      <md-input v-model="company.longitude" required />
                    </md-field>
                </div>
              </div>
            </div>

          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button type="submit" class="md-success">{{ $t('companies.add_company') }}</md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    company: {
      type: "companies",
      name: null,
      email: null
    },

    image: "",
    default_img: process.env.VUE_APP_BASE_URL + "/img/placeholder.jpg",
    file: null,
    provinces: []
  }),

  created() {
    this.getProvinces();
    this.initMap();
    this.getStatuses();
  },

  methods: {
    async getProvinces() {
        await this.$store.dispatch("provinces/list");
        this.provinces = this.$store.getters["provinces/list"];
    },

    async add() {
      try {
        this.resetApiValidation();
        delete this.company.id;

        await this.$store.dispatch("companies/add", this.company);
        this.company = await this.$store.getters["companies/company"];

        if (this.file) {
          // upload image and get image url
          await this.$store.dispatch("companies/upload", {
            company: this.company,
            image: this.file,
          });
          this.company.image = await this.$store.getters["companies/image_path"];
          // update company with image url
          await this.$store.dispatch("companies/update", this.company);
          this.company = await this.$store.getters["companies/company"];
        }

        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );

        this.goBack();
      } catch (e) {
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            e.response.data.errors[0].title
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            "alerts/error",
            this.$t('message.error_generic')
          );
          this.setApiValidation(e.response.data.errors);
        }
        if (this.company.id) {
          await this.$store.dispatch("companies/destroy", this.company.id);
        }
      }
    },

    getStatuses() {
      this.available_statuses = [{ 'id': 'active', 'label': this.$t('companies.status_active') }, { 'id': 'inactive', 'label': this.$t('companies.status_inactive') }];
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = file;
      };
      reader.readAsDataURL(file);
    },

    removeImage() {
      this.image = null;
    },

    goBack() {
      this.$router.push({ name: "companies.list_companies" }).catch(err => {});
    },

    initMap() {
        var self = this;

        this.$gmapLoader.load().then(function(google) {
            const myLatLng = new google.maps.LatLng(process.env.VUE_APP_DEFAULT_LATITUDE, process.env.VUE_APP_DEFAULT_LONGITUDE);
            const mapOptions = {
                zoom: 13,
                center: myLatLng,
                scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
                disableDefaultUI: true, // a way to quickly hide all controls
                zoomControl: true
            }

            const map = new google.maps.Map(
                document.getElementById("map"),
                mapOptions
            );

            const marker = new google.maps.Marker({
                position: myLatLng,
                title: "Regular Map!",
                draggable: true
            });
            marker.setMap(map);

            google.maps.event.addListener(marker,'dragend', function (event) {
                self.setCoordinates(this.getPosition());
            });
        });
    },

    setCoordinates(markerPosition) {
        this.company.latitude = markerPosition.lat();
        this.company.longitude = markerPosition.lng();
        this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
.spacing { padding-bottom: 1rem; }
#map { height:250px; width:250px; margin-right:20px; }
@media (min-width:768px) {
    #gmap-position { display:flex; }
}
</style>
