<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>directions</md-icon>
          </div>
          <h4 class="title">{{ $t('companies.edit_reservations') }}</h4>
        </md-card-header>

        <form @submit.prevent="update_reservations">
          <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item text-left h4">
                    {{ company.name }}
                </div>
                <div class="md-layout-item text-right">
                  <md-button @click="goBack" class="md-primary md-dense">
                    {{ $t('companies.back_company') }}
                  </md-button>
                </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('company.reservations_web') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.reservations_web" />
                  <validation-error :errors="apiValidationErrors.reservations_web" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('company.reservations_email') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.reservations_email" />
                  <validation-error :errors="apiValidationErrors.reservations_email" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('company.reservations_phone') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.reservations_phone" />
                  <validation-error :errors="apiValidationErrors.reservations_phone" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('company.reservations_facebook') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-input v-model="company.reservations_facebook" />
                  <validation-error :errors="apiValidationErrors.reservations_facebook" />
                </md-field>
              </div>
            </div>

            <div class="md-layout spacing">
              <label class="md-layout-item md-size-25 md-form-label">
                {{ $t('companies.reservations_guidelines') }}
              </label>
              <div class="md-layout-item">
                <md-field class="md-invalid">
                  <md-textarea v-model="company.reservations_guidelines"></md-textarea>
                </md-field>
              </div>
            </div>

          </md-card-content>

          <md-card-actions md-alignment="right">
            <md-button type="submit" class="md-success">{{ $t('action.save_reservations') }}</md-button>
          </md-card-actions>
        </form>
      </md-card>
    </div>
  </div>
</template>

<script>
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";

export default {
  components: { ValidationError },

  mixins: [formMixin],

  data: () => ({
    company: {
      type: "companies",
      name: null,
      reservations_email: null,
      reservations_facebook: null,
      reservations_phone: null,
      reservations_web: null,
      reservations_guidelines: null
    }
  }),

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("companies/get", id);
        this.company = this.$store.getters["companies/company"];
      } catch (e) {
        console.log(e);
        await this.$store.dispatch(
          "alerts/error",
          this.$t('message.error_generic')
        );
      }
    },

    async update_reservations() {
      try {
        await this.$store.dispatch("companies/update", this.company);
        await this.$store.dispatch(
          "alerts/success",
          this.$t('message.changes_save_success'),
        );
        this.goBack();
      } catch (e) {
        console.log(e);
        if (e.response.data.errors[0]) {
          await this.$store.dispatch(
            "alerts/error",
            this.$t(e.response.data.errors[0].title) + (e.response.data.errors[0].detail ? ' ' + this.$t(e.response.data.errors[0].detail) : '')
          );
          this.setApiValidation(e.response.data.errors);
        } else {
          await this.$store.dispatch(
            "alerts/error",
            this.$t('message.error_generic')
          );
        }
      }
    },

    goBack() {
      this.$router.push({ name: "companies.edit_company", id: this.company.id }).catch(err => {});
    }
  }
};
</script>

<style lang="scss">
.spacing { padding-bottom: 1rem; }
</style>